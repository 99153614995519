<template>
    <main class="page-content" role="main" v-if="loading">
        <div class="container-fluid">
            <form class="form-card">
                <div class="row justify-center">
                    <div class="col-md-12 col-lg-12 col-xl-6">
                        <panel id="panel-1">
                            <template v-slot:header>
                                <h2 class="my-2">
                                    <span
                                        class="icon-stack fs-xxl mr-2 d-flex justify-content-center align-items-center"
                                    >
                                        <i
                                            class="base base-7 icon-stack-4x opacity-100 color-primary-700"
                                        ></i>
                                        <i
                                            class="fas fa-cog icon-stack-2x opacity-100 color-white"
                                        ></i>
                                    </span>
                                    {{ $t('companies.settings.name') }}
                                </h2>
                            </template>
                            <div class="panel-container collapse show" style="">
                                <div class="panel-content mt-4 pb-2">
                                    <h2
                                        class="text-lg leading-6 font-medium text-blue-500"
                                    >
                                        {{
                                            $t(
                                                'companies.settings.contact.title'
                                            )
                                        }}
                                    </h2>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 my-4"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="adminEmail"
                                                    >{{
                                                        $t(
                                                            'companies.settings.contact.mail_admin'
                                                        )
                                                    }}
                                                    *</label
                                                >
                                                <input
                                                    id="adminEmail"
                                                    v-model="company.mail_admin"
                                                    required
                                                    class="form-control"
                                                    type="text"
                                                />
                                                <p
                                                    v-if="
                                                        isSettingsSend &&
                                                        nameMessage
                                                    "
                                                    class="has-text-danger font-bold text-center is-size-7"
                                                >
                                                    {{ nameMessage }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <div class="form-group">
                                                <h3
                                                    class="text-lg leading-6 font-medium text-blue-500"
                                                >
                                                    Logo
                                                </h3>
                                                <FormFileUpload
                                                    class="formFileUpload"
                                                    :attribute="logoAttribute"
                                                    :isOnePicture="true"
                                                    :isLogo="true"
                                                    @add_company_files="
                                                        addCompanyLogo
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </panel>
                        <div class="d-flex justify-center">
                            <button
                                class="btn btn-primary"
                                @click.prevent="sendCompanySettingsHandler()"
                            >
                                {{ $t('companies.settings.change_settings') }}
                            </button>
                        </div>
                        <div
                            class="d-flex justify-center"
                            v-if="isSettingsModified"
                        >
                            <p>
                                {{ $t('companies.settings.settings_modified') }}
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-xl-6">
                        <panel id="panel-1">
                            <template v-slot:header>
                                <h2 class="my-2">
                                    <span
                                        class="icon-stack fs-xxl mr-2 d-flex justify-content-center align-items-center"
                                    >
                                        <i
                                            class="base base-7 icon-stack-4x opacity-100 color-primary-700"
                                        ></i>
                                        <i
                                            class="fas fa-cog icon-stack-2x opacity-100 color-white"
                                        ></i>
                                    </span>
                                    Configuration de l'en-tête mail et SMTP
                                </h2>
                            </template>
                            <div class="panel-container collapse show" style="">
                                <div class="panel-content mt-4 pb-2">
                                    <h2
                                        class="text-lg leading-6 font-medium text-blue-500"
                                    >
                                        {{
                                            $t(
                                                'companies.settings.sending_mails.title'
                                            )
                                        }}
                                    </h2>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="companyName"
                                                    >{{
                                                        $t(
                                                            'companies.settings.sending_mails.sender'
                                                        )
                                                    }}
                                                    *</label
                                                >
                                                <input
                                                    id="companyName"
                                                    v-model="
                                                        company.settings_sender_name
                                                    "
                                                    required
                                                    class="form-control"
                                                    type="text"
                                                />
                                                <p
                                                    v-if="
                                                        isSettingsSend &&
                                                        nameMessage
                                                    "
                                                    class="has-text-danger font-bold text-center is-size-7"
                                                >
                                                    {{ nameMessage }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="companyEmail"
                                                    >{{
                                                        $t(
                                                            'companies.settings.sending_mails.sender_mail'
                                                        )
                                                    }}
                                                    *</label
                                                >
                                                <input
                                                    id="companyEmail"
                                                    v-model="
                                                        company.settings_sender_mail
                                                    "
                                                    required
                                                    class="form-control"
                                                    type="email"
                                                />
                                            </div>
                                            <p
                                                v-if="
                                                    isSettingsSend &&
                                                    mailMessage
                                                "
                                                class="has-text-danger font-bold text-center is-size-7"
                                            >
                                                {{ mailMessage }}
                                            </p>
                                        </div>
                                    </div>

                                    <h2
                                        class="text-lg leading-6 font-medium text-blue-500 pt-4"
                                    >
                                        {{
                                            $t(
                                                'companies.settings.sending_mails.advanced_config'
                                            )
                                        }}
                                    </h2>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="mailProtocol"
                                                >
                                                    {{
                                                        $t(
                                                            'companies.settings.sending_mails.protocol'
                                                        )
                                                    }}</label
                                                >
                                                <select
                                                    v-model="
                                                        company.settings_mail_protocol
                                                    "
                                                    id="mailProtocol"
                                                    name="mailProtocol"
                                                    class="form-control select"
                                                >
                                                    <option value="smtp">
                                                        SMTP
                                                    </option>
                                                    <option value="pop">
                                                        POP
                                                    </option>
                                                    <option value="imap">
                                                        IMAP
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="mailHost"
                                                >
                                                    {{
                                                        $t(
                                                            'companies.settings.sending_mails.host'
                                                        )
                                                    }}</label
                                                >
                                                <input
                                                    id="mailHost"
                                                    v-model="
                                                        company.settings_mail_host
                                                    "
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="mailPort"
                                                >
                                                    {{
                                                        $t(
                                                            'companies.settings.sending_mails.port'
                                                        )
                                                    }}</label
                                                >
                                                <input
                                                    id="mailPort"
                                                    v-model="
                                                        company.settings_mail_port
                                                    "
                                                    class="form-control"
                                                    type="number"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="mailUsername"
                                                >
                                                    {{
                                                        $t(
                                                            'companies.settings.sending_mails.username'
                                                        )
                                                    }}</label
                                                >
                                                <input
                                                    id="mailUsername"
                                                    v-model="
                                                        company.settings_mail_username
                                                    "
                                                    class="form-control"
                                                    type="text"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="mailPassword"
                                                >
                                                    {{
                                                        $t(
                                                            'companies.settings.sending_mails.password'
                                                        )
                                                    }}</label
                                                >
                                                <input
                                                    id="mailPassword"
                                                    v-model="
                                                        company.settings_mail_password
                                                    "
                                                    class="form-control"
                                                    type="password"
                                                />
                                            </div>
                                            <p
                                                v-if="
                                                    isSettingsSend &&
                                                    passwordmessage
                                                "
                                                class="has-text-danger font-bold text-center is-size-7 mx-4"
                                            >
                                                {{ passwordmessage }}
                                            </p>
                                        </div>
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-6 mt-5"
                                        >
                                            <div class="form-group">
                                                <label
                                                    class="form-label"
                                                    for="mailPasswordConfirmation"
                                                >
                                                    {{
                                                        $t(
                                                            'companies.settings.sending_mails.password_confirmation'
                                                        )
                                                    }}</label
                                                >
                                                <input
                                                    id="mailPasswordConfirmation"
                                                    v-model="
                                                        company.settings_mail_password_confirmation
                                                    "
                                                    class="form-control"
                                                    type="password"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <div class="form-group">
                                                <p class="text-sm">
                                                    Mise en page html
                                                </p>
                                                <b-field class="w-full h-full">
                                                    <b-input
                                                        v-model="
                                                            headerMail.settings_mail_template
                                                        "
                                                        type="textarea"
                                                    ></b-input>
                                                </b-field>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div
                                            class="col-md-12 col-lg-12 col-xl-12 mt-5"
                                        >
                                            <div class="form-group">
                                                <p class="text-sm">
                                                    Autres images
                                                </p>
                                                <FormFileUpload
                                                    class="formFileUpload"
                                                    :attribute="
                                                        picturesAttribute
                                                    "
                                                    :isOnePicture="false"
                                                    @add_company_files="
                                                        addCompanyPictures
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </panel>
                        <div class="d-flex justify-center">
                            <button
                                class="btn btn-primary"
                                @click.prevent="sendHeaderMailsHandler()"
                            >
                                {{ $t('companies.settings.change_settings') }}
                            </button>
                        </div>
                        <div
                            class="d-flex justify-center"
                            v-if="isHeaderMailModified"
                        >
                            <p>
                                {{ $t('companies.settings.settings_modified') }}
                            </p>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </main>
</template>

<script>
import Panel from '@/components/UI/Panel'
import FormFileUpload from '@/components/Model/Form/FileUploadCompanyForm'
import { mapActions, mapState } from 'vuex'
export default {
    name: 'Settings',
    components: {
        Panel,
        FormFileUpload
    },
    data() {
        return {
            loading: false,
            isSettingsSend: false,
            isHeaderMailModified: false,
            isSettingsModified: false,
            logoAttribute: {
                config: {
                    'accepted-ext': [
                        'png',
                        'jpg',
                        'jpeg',
                        'PNG',
                        'JPG',
                        'JPEG'
                    ],
                    max: 1,
                    min: 0,
                    type: 'pictures',
                    value: null
                }
            },
            picturesAttribute: {
                config: {
                    'accepted-ext': [
                        'png',
                        'jpg',
                        'jpeg',
                        'PNG',
                        'JPG',
                        'JPEG'
                    ],
                    max: 10,
                    min: 0,
                    type: 'pictures',
                    value: null
                }
            },
            nameMessage: '',
            passwordmessage: '',
            mailMessage: '',
            mailregExp:
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        }
    },
    created() {
        this.getCompanySettingsHandler()
    },
    computed: {
        ...mapState('company', {
            company: (state) => state.companySettings,
            headerMail: (state) => state.companyHeaderMail
        })
    },
    methods: {
        ...mapActions('company', [
            'getCompanySettings',
            'sendCompanySettings',
            'sendCompanyHeaderMail',
            'sendCompanyAdminEmailAndLogo'
        ]),
        async sendCompanySettingsHandler() {
            this.isSettingsSend = true
            this.sendCompanyAdminEmailAndLogo()
                .then(() => {
                    this.isSettingsModified = true
                })
                .catch((error) => {
                    this.$store.dispatch('dispatchError', error)
                })
        },
        sendHeaderMailsHandler() {
            this.confirmName(this.company.settings_sender_name)
            this.confirmMail(this.company.settings_sender_mail)
            this.confirmPassword(
                this.company.settings_mail_password,
                this.company.settings_mail_password_confirmation
            )
            !this.nameMessage && !this.mailMessage && !this.passwordmessage
                ? this.sendCompanySettings()
                      .then(() => {
                          this.sendCompanyHeaderMail()
                              .then(() => {
                                  this.isHeaderMailModified = true
                              })
                              .catch((error) => {
                                  this.$store.dispatch('dispatchError', error)
                              })
                      })
                      .catch((error) => {
                          this.$store.dispatch('dispatchError', error)
                      })
                : null
        },
        getCompanySettingsHandler() {
            this.getCompanySettings()
                .then(() => {
                    this.loading = true
                    this.picturesAttribute.value = this.headerMail
                        .settings_pictures
                        ? this.headerMail.settings_pictures
                        : null
                    this.logoAttribute.value = this.company.settings_logo
                        ? this.company.settings_logo
                        : null
                })
                .catch((error) => {
                    this.$store.dispatch('dispatchError', error)
                })
        },
        // vérifie la confirmation du mot de passe (correspondance entre les deux mots de passes saisies)
        confirmPassword(password, passwordConfirmation) {
            if (password !== passwordConfirmation) {
                this.passwordmessage = this.$t(
                    'error.validation.password_mismatch'
                )
            } else {
                this.passwordmessage = ''
            }
        },
        // vérifie la saisie du champ email
        confirmMail(email) {
            if (!email) {
                this.mailMessage = this.$t('error.validation.required')
            } else if (!this.mailregExp.test(email)) {
                this.mailMessage = this.$t('error.validation.mail_valid')
            } else {
                this.mailMessage = ''
            }
        },
        confirmName(name) {
            if (!name) {
                this.nameMessage = this.$t('error.validation.required')
            } else {
                this.nameMessage = ''
            }
        },
        addCompanyLogo(value) {
            this.company.settings_logo = value
        },
        addCompanyPictures(value) {
            this.headerMail.settings_pictures = value
        }
    }
}
</script>
<style scoped>
.select {
    border: 2px solid #3d4d5d !important;
    font-size: 0.8em;
    height: 2.9em;
}
</style>
<style>
.ck-editor__editable_inline {
    min-height: 150px;
}
.ck-editor__editable {
    max-height: 500px;
}
</style>
