import { render, staticRenderFns } from "./FileUploadCompanyForm.vue?vue&type=template&id=530ee0ee&scoped=true&"
import script from "./FileUploadCompanyForm.vue?vue&type=script&lang=js&"
export * from "./FileUploadCompanyForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530ee0ee",
  null
  
)

export default component.exports