var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-field',{staticClass:"w-full",attrs:{"type":"is-danger","label":`${_vm.attribute.label ? _vm.attribute.label : ''} ${
            _vm.asterisk ? _vm.asterisk : ''
        }`}},[_c('b-field',{attrs:{"type":"is-info","message":_vm.fileExtension}},[_c('b-upload',{attrs:{"drag-drop":"","multiple":!_vm.isOnePicture,"expanded":""},on:{"input":_vm.showFiles},model:{value:(_vm.dropFiles),callback:function ($$v) {_vm.dropFiles=$$v},expression:"dropFiles"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-large"}})],1),_c('p',[_vm._v("Drop your files here or click to upload")])])])])],1)],1),_c('b-field',{attrs:{"message":_vm.message,"type":"is-danger font-bold text-center"}}),(this.attribute.config.type === 'pictures')?_c('div',{staticClass:"tile is-ancestor"},[(!_vm.isOnePicture)?_c('div',_vm._l((_vm.result),function(file,index){return _c('div',{key:index,staticClass:"tile is-vertical is-one-third card"},[_c('div',{staticClass:"card-image"},[_c('figure',{staticClass:"image is-4by3"},[_c('img',{attrs:{"alt":"Placeholder image","src":file.encodedFile
                                    ? file.encodedFile
                                    : `${_vm.baseUrl}/storage/${file.id}?token=${_vm.token}`}})])]),_c('div',{staticClass:"card-footer"},[_c('a',{staticClass:"card-footer-item",attrs:{"href":"#"}},[_vm._v(_vm._s(file.name))]),_c('a',{staticClass:"card-footer-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteDropFile(index)}}},[_vm._v("Delete ")])])])}),0):(_vm.result)?_c('div',{staticClass:"tile is-vertical is-one-third card"},[_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"media"},[_c('figure',{staticClass:"image is-128x128 m-auto"},[_c('img',{attrs:{"alt":"Placeholder image","src":_vm.result.encodedFile
                                    ? _vm.result.encodedFile
                                    : `${_vm.baseUrl}/storage${_vm.isLogo ? '/logo' : ''}/${_vm.result.id}?token=${_vm.token}`}})])])]),_c('div',{staticClass:"card-footer"},[_c('a',{staticClass:"card-footer-item",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.result.encodedFile ? _vm.result.name : _vm.result.name))]),_c('a',{staticClass:"card-footer-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteDropFile()}}},[_vm._v("Delete")])])]):_vm._e()]):_c('div',[(!_vm.isOnePicture && _vm.result && _vm.result.length >= 1)?_c('div',{staticClass:"tags"},_vm._l((_vm.result),function(file,index){return _c('span',{key:index,staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(file.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile(index)}}})])}),0):_c('div',{staticClass:"tags"},[_c('span',{staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(_vm.result.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile()}}})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }